import { COMMON } from '@/constants/store.constant';
import { getHistoryJobExecutions } from '@/services/api/exchange-monitors.service';
import { dateDiff } from '@/utils/global.util';

const parseInformation = (data) => {
  return data.map((item) => {
    const lastExecutedDate = item.lastExecutedDate;
    const createdAt = item.createdAt;
    return {
      id: item.profileId,
      name: item.parserDTO?.displayName,
      jobId: item.id,
      profileId: item.profileId,
      batchId: item.awsBatchUuid,
      status: item.status,
      stage: item.stage,
      newFiles: item.newFoundFiles,
      foundFiles: item.foundFiles,
      foundLinks: item.foundLinks,
      url: item.s3ReportUrl,
      duration: dateDiff(lastExecutedDate, createdAt),
      crawlingTimeframeStart: item.crawlingTimeframeStart,
      totalFiles: item.totalFoundFiles,
      lastExecutedDate: item.lastExecutedDate,
      createdAt: item.createdAt,
      error: item.error,
    };
  });
};

const initialState = () => ({
  jobExecutionsHistory: [],
  filterJobExecutionsHistory: [],
  params: initialParams(),
  isLastPage: true,
  totalElements: 0,
  shouldRefresh: false,
  initialFetch: false,
  filterText: null,
  filterStatus: 'COMPLETED',
  startDate: null,
  endDate: null,
});

const initialParams = () => ({
  page: 0,
  size: 10,
  parserId: null,
  profileUuid: null,
  status: null,
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    [COMMON.UPDATE_STATE] (state, { name, value }) {
      state[name] = value;
    },
    [COMMON.UPDATE_STATE_PAYLOAD] (state, payload) {
      Object.assign(state, { ...payload });
    },
    [COMMON.UPDATE_PARAM] (state, { name, value }) {
      state.params[name] = value;
    },
    [COMMON.UPDATE_PARAMS] (state, payload) {
      state.params = { ...state.params, ...payload };
    },
    [COMMON.RESET_STATE] (state) {
      Object.assign(state, initialState());
    },
    [COMMON.RESET_PARAMS] (state) {
      Object.assign(state.params, initialParams());
    },
  },
  actions: {
    async [COMMON.FETCH_DATA] ({ state, commit }, { shouldReset }) {
      let jobExecutionsHistory = [];
      let isLastPage = false;
      let totalElements = 0;
      if (state.jobExecutionsHistory.length > 0 && !state.shouldRefresh) {
        jobExecutionsHistory = state.jobExecutionsHistory;
        isLastPage = state.isLastPage;
        totalElements = state.totalElements;
      } else {
        const { data: dataHistoryJobExecutions } = await getHistoryJobExecutions(state.params);
        jobExecutionsHistory = dataHistoryJobExecutions.content;
        jobExecutionsHistory = parseInformation(jobExecutionsHistory);
        isLastPage = dataHistoryJobExecutions.last;
        totalElements = dataHistoryJobExecutions.totalElements;
      }

      commit(COMMON.UPDATE_STATE, { name: 'jobExecutionsHistory', value: shouldReset ? jobExecutionsHistory : [...state.jobExecutionsHistory, ...jobExecutionsHistory] });
      commit(COMMON.UPDATE_STATE, { name: 'filterJobExecutionsHistory', value: shouldReset ? jobExecutionsHistory : [...state.filterJobExecutionsHistory, ...jobExecutionsHistory] });
      commit(COMMON.UPDATE_STATE, { name: 'isLastPage', value: isLastPage });
      commit(COMMON.UPDATE_STATE, { name: 'totalElements', value: totalElements });
    },
  },
  getters: {
    [COMMON.GET_STATE] (state) {
      return state.filterJobExecutionsHistory;
    },
    [COMMON.IS_LAST_PAGE] (state) {
      return state.isLastPage;
    },
    [COMMON.SHOULD_REFRESH] (state) {
      return state.shouldRefresh;
    },
    [COMMON.INITIAL_FETCH] (state) {
      return state.initialFetch;
    },
    [COMMON.GET_STATE_BY_NAME] (state) {
      return (name) => state[name];
    },
  },
};
