import '@/plugins/mds-layout';
import '@/plugins/portal-vue';
import '@/plugins/vue-notifications';
import '@/plugins/vee-validate';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import translate from '@/locales/index.js';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en',
  messages: translate,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
