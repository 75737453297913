import { COMMON } from '@/constants/store.constant';
import { getExchangeMonitors } from '@/services/api/exchange-monitors.service';

const initialState = () => ({
  exchangeMonitors: [],
  filterExchangeMonitors: [],
  params: {
    page: 0,
    size: 40,
    name: null,
    active: null,
  },
  isLastPage: false,
  shouldRefresh: false,
});

const filterExchangeMonitorsByStatus = (exchangeMonitors, filterStatus) => {
  return filterStatus === null ? exchangeMonitors : exchangeMonitors.filter((exchangeMonitor) => exchangeMonitor.active === filterStatus);
};

const filterExchangeMonitorsByName = (exchangeMonitors, filterText) => {
  return filterText === null ? exchangeMonitors : exchangeMonitors.filter((exchangeMonitor) => exchangeMonitor.name.toLowerCase().includes(filterText.toLowerCase()) ||
    exchangeMonitor.externalProfileId.toLowerCase().includes(filterText.toLowerCase()) || exchangeMonitor.emUrl.toLowerCase().includes(filterText.toLowerCase()));
};

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    [COMMON.UPDATE_STATE] (state, { name, value }) {
      state[name] = value;
    },
    [COMMON.UPDATE_PARAM] (state, { name, value }) {
      state.params[name] = value;
    },
    [COMMON.RESET_STATE] (state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    async [COMMON.FETCH_DATA] ({ state, commit }, { shouldReset }) {
      commit(COMMON.UPDATE_PARAM, { name: 'page', value: shouldReset ? 0 : state.params.page + 1 });
      let exchangeMonitors = [];
      let isLastPage = false;
      if (state.exchangeMonitors.length > 0 && !state.shouldRefresh) {
        exchangeMonitors = state.exchangeMonitors;
        isLastPage = state.isLastPage;
      } else {
        const { data: dataExchangeMonitors } = await getExchangeMonitors(state.params);
        exchangeMonitors = dataExchangeMonitors.content;
        isLastPage = dataExchangeMonitors.last;
      }

      commit(COMMON.UPDATE_STATE, { name: 'exchangeMonitors', value: shouldReset ? exchangeMonitors : [...state.exchangeMonitors, ...exchangeMonitors] });
      commit(COMMON.UPDATE_STATE, { name: 'filterExchangeMonitors', value: shouldReset ? exchangeMonitors : [...state.exchangeMonitors, ...exchangeMonitors] });
      commit(COMMON.UPDATE_STATE, { name: 'isLastPage', value: isLastPage });
    },
    [COMMON.FILTER_DATA] ({ state, commit }) {
      const data = filterExchangeMonitorsByName(filterExchangeMonitorsByStatus(state.exchangeMonitors, state.params.active), state.params.name);
      commit(COMMON.UPDATE_STATE, { name: 'filterExchangeMonitors', value: data });
    },
  },
  getters: {
    [COMMON.GET_STATE] (state) {
      return state.filterExchangeMonitors;
    },
    [COMMON.IS_LAST_PAGE] (state) {
      return state.isLastPage;
    },
    [COMMON.SHOULD_REFRESH] (state) {
      return state.shouldRefresh;
    },
  },
};
