import { COMMON } from '@/constants/store.constant';
import { getCrawledDocuments } from '@/services/api/exchange-monitors.service';

const initialState = () => ({
  crawledDocuments: [],
  filterCrawledDocuments: [],
  isLastPage: true,
  filterText: '',
  filterStatus: null,
  totalElements: 0,
  shouldRefresh: false,
  params: {
    page: 0,
    size: 10,
    jobId: null,
    showSuccess: false,
    downloadedLink: null,
    s3Path: null,
    attributes: null,
    merged: null,
  },
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    [COMMON.UPDATE_STATE] (state, { name, value }) {
      state[name] = value;
    },
    [COMMON.UPDATE_PARAM] (state, { name, value }) {
      state.params[name] = value;
    },
    [COMMON.UPDATE_PARAMS] (state, payload) {
      state.params = { ...state.params, ...payload };
    },
    [COMMON.RESET_STATE] (state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    async [COMMON.FETCH_DATA] ({ state, commit }) {
      let data = { content: [], totalElements: 0 };
      if (state.crawledDocuments.length > 0 && !state.shouldRefresh) {
        data.content = state.crawledDocuments;
        data.totalElements = state.totalElements;
      } else {
        const result = await getCrawledDocuments(state.params);
        data = result.data;
      }
      commit(COMMON.UPDATE_STATE, { name: 'totalElements', value: data.totalElements });
      commit(COMMON.UPDATE_STATE, { name: 'crawledDocuments', value: data.content });
      commit(COMMON.UPDATE_STATE, { name: 'filterCrawledDocuments', value: data.content });
    },
    async getCompleted ({ state, commit, dispatch }) {
      commit(COMMON.UPDATE_PARAM, { name: 'showSuccess', value: true });
      commit(COMMON.UPDATE_STATE, { name: 'shouldRefresh', value: true });
      await dispatch(COMMON.FETCH_DATA, { shouldReset: true });
    },
    async getErrors ({ state, commit, dispatch }) {
      commit(COMMON.UPDATE_PARAM, { name: 'showSuccess', value: false });
      commit(COMMON.UPDATE_STATE, { name: 'shouldRefresh', value: true });
      await dispatch(COMMON.FETCH_DATA, { shouldReset: true });
    },
  },
  getters: {
    [COMMON.GET_STATE] (state) {
      return state.filterCrawledDocuments;
    },
    [COMMON.IS_LAST_PAGE] (state) {
      return state.isLastPage;
    },
    [COMMON.SHOULD_REFRESH] (state) {
      return state.shouldRefresh;
    },
    [COMMON.GET_STATE_BY_NAME] (state) {
      return (name) => state[name];
    },
  },
};
