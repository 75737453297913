import http from '@/plugins/http';
import ENDPOINTS from '@/constants/endpoints.constant';

/**
 * @param {object} params Filter information
 * @param {object} params.companyId Company id in Morningstar's database
 * @returns {promise} Promise object containing a provider that matches the given companyId
 */
export const getProviderByCompanyId = ({ companyId }) => http.get(`${ENDPOINTS.PROVIDER}?companyId=${companyId}`);

/**
 * @param {object} [params] Pagination information
 * @param {number} [params.page] Page number
 * @param {number} [params.size] Amount of providers per page
 * @param {string} [params.nameOrCompanyId] Complete or partial name or id of a company
 * @param {boolean} [params.enabled] Whether a provider is active or not
 * @returns {promise} Promise object containing all providers that match the given params
 */
export const getProviders = (params) => http.get(ENDPOINTS.PROVIDER, { params });

/**
 * @param {object} payload Provider properties to be created
 * @returns {promise} Promise object containing the created provider
 */
export const postProvider = (payload) => http.post(ENDPOINTS.PROVIDER, payload);

/**
 * @param {object} file CSV file containing all the companyIds to be created
 * @returns {promise} Promise containing a CSV file with the results of each companyId's insertion
 */
export const postBatchProviders = (file) => http.post(`${ENDPOINTS.PROVIDER}/batch`, file, {
  header: {
    contentType: 'text/csv',
    responseType: 'blob',
  },
});

/**
 * @param {object} payload Provider properties to be uploaded
 * @returns {promise} Promise object containing the updated provider
 */
export const putProvider = ({ id, ...payload }) => http.put(`${ENDPOINTS.PROVIDER}/${id}`, payload);

/**
 * @returns {promise} Promise object containing all documents types
 */
export const getDocTypes = () => http.get(`${ENDPOINTS.PROVIDER}/doctypes`);
