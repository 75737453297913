import http from '@/plugins/http';
import endpoints from '@/constants/endpoints.constant';

/**
 * Services to authenticate in the app
 * @param {object} auth Authentication object
 * @param {string} auth.username Username of Morningstar's credential
 * @param {string} auth.password Password of Morningstar's credential
 * @returns {promise} Promise Object with cookies option on headers
 */
export const logIn = (auth) => http.post(endpoints.LOGIN, null, { auth });

/**
 * services to close session and remove token on backend
 * @returns {promise} Promise Object with redirect option
 */
export const logOut = () => http.post(endpoints.LOGOUT);
