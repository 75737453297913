import Vue from 'vue';
import Vuex from 'vuex';
import { MODULE } from '@/constants/store.constant';
import providersModule from '@/store/modules/providers.module';
import providerModule from '@/store/modules/provider.module';
import providerLinksModule from '@/store/modules/provider-links.module.js';
import collectionModule from '@/store/modules/collection.module';
import jobModule from '@/store/modules/job.module';
import authModule from '@/store/modules/auth.module';
import exchangeMonitorsModule from '@/store/modules/exchange-monitors.module.js';
import exchangeMonitorModule from '@/store/modules/exchange-monitor.module.js';
import parsersModule from '@/store/modules/parsers.js';
import featureFlagsModule from '@/store/modules/featureFlags.module.js';
import parsersjobSchedulers from '@/store/modules/parsers-job-schedulers.module.js';
import collectionExchangeMonitorModule from '@/store/modules/collection-exchange-monitor-module.js';
import historyExchangeMonitorsModule from '@/store/modules/history-exchange-monitor.module.js';
import crawledDocumentsEmModule from '@/store/modules/crawled-documents-em.module.js';
import collectionPrEcModule from '@/store/modules/collection-pr-ec-module.js';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    [MODULE.PROVIDERS]: providersModule,
    [MODULE.PROVIDER]: providerModule,
    [MODULE.PROVIDER_LINKS]: providerLinksModule,
    [MODULE.COLLECTION]: collectionModule,
    [MODULE.JOB]: jobModule,
    [MODULE.AUTH]: authModule,
    [MODULE.EXCHANGE_MONITORS]: exchangeMonitorsModule,
    [MODULE.EXCHANGE_MONITOR]: exchangeMonitorModule,
    [MODULE.PARSERS]: parsersModule,
    [MODULE.FEATURE_FLAGS]: featureFlagsModule,
    [MODULE.PARSERS_JOB_SCHEDULERS]: parsersjobSchedulers,
    [MODULE.COLLECTION_EXCHANGE_MONITOR_MODULE]: collectionExchangeMonitorModule,
    [MODULE.HISTORY_EXCHANGE_MONITOR]: historyExchangeMonitorsModule,
    [MODULE.CRAWLED_DOCUMENTS_EM]: crawledDocumentsEmModule,
    [MODULE.COLLECTION_PR_EC_MODULE]: collectionPrEcModule,
  },
});
