import http from '@/plugins/http';
import ENDPOINTS from '@/constants/endpoints.constant';

/**
 * @returns {promise} Promise object containing all S3 Buckets
 */
export const getS3Buckets = () => http.get(`${ENDPOINTS.PR_EC}${ENDPOINTS.S3_CONFIGS}/s3-buckets`);

/**
 * @returns {promise} Promise object containing last job executions
 */
export const getLastJobExecutions = () => http.get(`${ENDPOINTS.PR_EC}${ENDPOINTS.PR_EC_JOBS}/last-executions`);
