import http from '@/plugins/http';
import ENDPOINTS from '@/constants/endpoints.constant';

/**
 * @param {object} params Filter information
 * @param {object} params.name Name of the Exchange Monitor profile
 * @returns {promise} Promise object containing a Exchange Monitor that matches the given name
 */
export const getExchangeMonitorByName = ({ name }) => http.get(`${ENDPOINTS.EXCHANGE_MONITORS}/profiles?name=${name}`);

/**
 * @param {object} params.exchangeId Id of the Exchange Monitor profile
 * @returns {promise} Promise object containing a Exchange Monitor that matches the given name
 */
export const getExchangeMonitorById = ({ exchangeId }) => http.get(`${ENDPOINTS.EXCHANGE_MONITORS}/profiles/${exchangeId}`);

/**
 * @param {object} params.exchangeId Id of the Exchange Monitor profile
 * @returns {promise} Promise object containing a Exchange Monitor parsers
 */
export const getExchangeParsers = ({ exchangeId }) => http.get(`${ENDPOINTS.EXCHANGE_MONITORS}/parsers/profile/${exchangeId}`);

/**
 * @param {object} params.parserId Id of the Parser
 * @returns {promise} Promise object containing a Exchange Monitor parsers
 */
export const getParserJobSchedulers = ({ parserId }) => http.get(`${ENDPOINTS.EXCHANGE_MONITORS}/job_schedulers/parser/${parserId}`);

/**
 * @returns {promise} Promise object containing parsers job executions
 */
export const getJobExecutions = () => http.get(`${ENDPOINTS.EXCHANGE_MONITORS}/job_executions`);

/**
 * @param {object} [params] Params information
 * @param {number} [params.status] state of parse
 * @returns {promise} Promise object containing all Parsers
 */
export const getParsers = ({ params }) => http.get(`${ENDPOINTS.EXCHANGE_MONITORS}/parsers`, { params });

/**
 * @param {object} [params] Pagination information
 * @param {number} [params.page] Page number
 * @param {number} [params.size] Amount of ExchangeMonitors per page
 * @param {string} [params.name] Complete or partial name
 * @param {boolean} [params.enabled] Whether a ExchangeMonitor is active or not
 * @returns {promise} Promise object containing all ExchangeMonitors that match the given params
 */
export const getExchangeMonitors = (params) => http.get(`${ENDPOINTS.EXCHANGE_MONITORS}/profiles`, { params });

/**
 * @param {object} payload ExchangeMonitor properties to be created
 * @returns {promise} Promise object containing the created ExchangeMonitor
 */
export const postExchangeMonitor = (payload) => http.post(`${ENDPOINTS.EXCHANGE_MONITORS}/profiles`, payload);

/**
 * @param {object} payload ExchangeMonitor properties to be uploaded
 * @returns {promise} Promise object containing the updated ExchangeMonitor
 */
export const putExchangeMonitor = ({ id, ...payload }) => http.put(`${ENDPOINTS.EXCHANGE_MONITORS}/profiles/${id}`, payload);

/**
 * @param {object} id ExchangeMonitor id to be deleted
 * @returns {promise} Promise object containing the deleted ExchangeMonitor
 */
export const deleteExchangeMonitor = (id) => http.delete(`${ENDPOINTS.EXCHANGE_MONITORS}/profiles/${id}`);

/**
 * @param {string} awsBatchUuid Id of the AWS BatchUuid
 * @returns {promise} Promise object containing the URL of the report
 */
export const getAWSReportlURL = (awsBatchUuid) => http.get(`${ENDPOINTS.EXCHANGE_MONITORS}/report/${awsBatchUuid}`);

/**
 * @param {object} [params] Pagination information
 * @param {number} [params.page] Page number
 * @param {number} [params.size] Amount of providers per page
 * @returns {promise} Promise object containing parsers job executions
 */
export const getHistoryJobExecutions = (params) => http.get(`${ENDPOINTS.EXCHANGE_MONITORS}/job_executions/history`, { params });

/**
 * @param {object} [params] Pagination information
 * @param {number} [params.page] Page number
 * @param {number} [params.size] Amount of providers per page
 * @param {string} [params.jobId] Jon id to get all crawled documents
 * @param {boolean} [params.showSuccess] Filter by crawled documents successfully merged
 * @returns {promise} Promise object containing crawled documents for a JobID
 */
export const getCrawledDocuments = (params) => http.get(`${ENDPOINTS.EXCHANGE_MONITORS}/crawled_documents/`, { params });

/**
 * @param {object} payload parserId, and startDate to be executed
 * @returns {promise} Promise object containing the updated job
 */
export const scheduleJobNow = (payload) => http.post(`${ENDPOINTS.EXCHANGE_MONITORS}/job_schedulers/on-demand`, payload);
