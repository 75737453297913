import { getDocTypes } from '@/services/api/provider.service';
import { getS3Buckets } from '@/services/api/pr-ec.service';

const initialState = () => ({
  docTypes: [],
  originalDocTypes: [],
  s3Buckets: [],
  originalS3Buckets: [],
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_DOC_TYPES (state, docTypes) {
      state.docTypes = docTypes;
    },
    SET_ORIGINAL_DOC_TYPES (state, originalDocTypes) {
      state.originalDocTypes = originalDocTypes;
    },
    SET_S3_BUCKETS (state, s3Buckets) {
      state.s3Buckets = s3Buckets;
    },
    SET_ORIGINAL_S3_BUCKETS (state, originalS3Buckets) {
      state.originalS3Buckets = originalS3Buckets;
    },
  },
  actions: {
    async fetchDocTypes ({ commit }) {
      try {
        const { data: originalDocTypes } = await getDocTypes();
        commit('SET_ORIGINAL_DOC_TYPES', originalDocTypes);
        const docTypes = originalDocTypes.map((docType) => {
          return {
            text: docType.value,
            value: docType.id,
          };
        });
        commit('SET_DOC_TYPES', docTypes);
      } catch (error) {
        console.error('Error fetching doc types:', error);
      }
    },
    async fetchS3Buckets ({ commit }) {
      try {
        const { data: originalS3Buckets } = await getS3Buckets();
        commit('SET_ORIGINAL_S3_BUCKETS', originalS3Buckets);
        const s3Buckets = originalS3Buckets.map((s3Bucket) => {
          return {
            text: s3Bucket.bucketName,
            value: s3Bucket.id,
          };
        });
        commit('SET_S3_BUCKETS', s3Buckets);
      } catch (error) {
        console.error('Error fetching S3 buckets:', error);
      }
    },
  },
};
