<template>
  <notifications
    group="api-level"
    class="notification"
    position="top center"
    animation-type="velocity"
    ignore-duplicates
    :duration="5000"
  >
    <template
      slot="body"
      slot-scope="props"
    >
      <mds-notification-container>
        <mds-notification
          key="api-level-notification"
          :variation="props.item.type"
          :title="props.item.title"
          :text="props.item.text"
          @mds-notification-dismissed="removeNotification(props.item)"
        />
      </mds-notification-container>
    </template>
  </notifications>
</template>

<script>
import { MdsNotification, MdsNotificationContainer } from '@mds/notification';

export default {
  name: 'ApiNotification',
  components: {
    MdsNotificationContainer,
    MdsNotification,
  },
  methods: {
    removeNotification (notification) {
      this.$notify.close(notification.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  left: 0px !important;
  right: 0px !important;
  width: inherit !important;
}
</style>
