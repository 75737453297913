import { COMMON } from '@/constants/store.constant';
import { getJobLinks } from '@/services/api/job.service';

const initialState = () => ({
  job: [],
  params: {
    page: 0,
    size: 40,
    sort: null,
    statusCode: null,
    filesFoundCount: null,
    link: null,
    parentLink: null,
    linkText: null,
    pageTitle: null,
    headers: null,
    extension: null,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
  isLastPage: false,
  totalElements: 0,
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    [COMMON.UPDATE_STATE] (state, { name, value }) {
      state[name] = value;
    },
    [COMMON.UPDATE_PARAM] (state, { name, value }) {
      state.params[name] = value;
    },
    [COMMON.RESET_STATE] (state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    async [COMMON.FETCH_DATA] ({ state, commit }, { shouldReset, params }) {
      commit(COMMON.UPDATE_PARAM, { name: 'page', value: shouldReset ? 0 : state.params.page + 1 });
      const { data: { content: job, last: isLastPage, totalElements } } = await getJobLinks(params, state.params);
      commit(COMMON.UPDATE_STATE, { name: 'job', value: shouldReset ? job : [...state.job, ...job] });
      commit(COMMON.UPDATE_STATE, { name: 'isLastPage', value: isLastPage });
      commit(COMMON.UPDATE_STATE, { name: 'totalElements', value: totalElements });
    },
  },
  getters: {
    [COMMON.GET_STATE] (state) {
      return (name) => state[name];
    },
    [COMMON.IS_LAST_PAGE] (state) {
      return state.isLastPage;
    },
  },
};
