<template>
  <div>
    <component :is="this.$route.meta.layout || 'div'">
      <router-view />
    </component>
    <api-notification />
    <portal-target name="modals" />
  </div>
</template>

<script>
import ApiNotification from '@/components/ui/ApiNotification';

export default {
  components: {
    ApiNotification,
  },
};
</script>
