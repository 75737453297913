import { COMMON } from '@/constants/store.constant';
import { getExchangeParsers } from '@/services/api/exchange-monitors.service';

const initialState = () => ({
  parsers: [],
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    [COMMON.UPDATE_STATE] (state, { name, value }) {
      state[name] = value;
    },
    [COMMON.RESET_STATE] (state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    async [COMMON.FETCH_DATA] ({ commit }, { params }) {
      if (params.exchangeId) {
        const { data } = await getExchangeParsers(params);
        commit(COMMON.UPDATE_STATE, { name: 'parsers', value: data });
      }
    },
  },
  getters: {
    [COMMON.GET_STATE] (state) {
      return state.parsers;
    },
  },
};
