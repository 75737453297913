import { COMMON } from '@/constants/store.constant';
import { getExchangeMonitorById } from '@/services/api/exchange-monitors.service';

const initialState = () => ({
  exchange: {
    id: null,
    name: '',
    priority: null,
    active: false,
    approved: false,
    documentGroup: '',
    comment: '',
    checkDuplicates: false,
    dataSource: '',
    expDatePreDuplication: '',
    emUrl: '',
    creatorUser: 1,
    lastModifierUser: null,
    jobs: [],
  },
  dataSource: [],
  documentGroup: [],
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    [COMMON.UPDATE_STATE] (state, { name, value }) {
      state[name] = value;
    },
    [COMMON.RESET_STATE] (state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    async [COMMON.FETCH_DATA] ({ commit }, { params }) {
      if (params.exchangeId) {
        const { data } = await getExchangeMonitorById(params);
        commit(COMMON.UPDATE_STATE, { name: 'exchange', value: data });
      }
    },
  },
  getters: {
    [COMMON.GET_STATE] (state) {
      return (name) => state[name];
    },
  },
};
