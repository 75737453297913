import { COMMON } from '@/constants/store.constant';

const initialState = () => ({
  enableEM: true,
  deleteBtn: false,
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    [COMMON.SET_STATE] (state, { name, value }) {
      state[name] = value;
    },
    [COMMON.RESET_STATE] (state) {
      Object.assign(state, initialState());
    },
  },
  getters: {
    [COMMON.GET_STATE] (state) {
      return (name) => state[name];
    },
  },
};
