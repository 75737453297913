import Vue from 'vue';
import {
  MdsLayoutGrid,
  MdsRow,
  MdsCol
} from '@mds/layout-grid';

Vue.component('mds-layout-grid', MdsLayoutGrid);
Vue.component('mds-row', MdsRow);
Vue.component('mds-col', MdsCol);
