import { COMMON } from '@/constants/store.constant';
import { getProviderByCompanyId } from '@/services/api/provider.service';

const initialState = () => ({
  provider: [],
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    [COMMON.UPDATE_STATE] (state, { name, value }) {
      state[name] = value;
    },
    [COMMON.RESET_STATE] (state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    async [COMMON.FETCH_DATA] ({ commit }, { params: companyId }) {
      const { data: { content: provider } } = await getProviderByCompanyId(companyId);
      commit(COMMON.UPDATE_STATE, { name: 'provider', value: provider });
    },
  },
  getters: {
    [COMMON.GET_STATE] (state) {
      return state.provider;
    },
  },
};
