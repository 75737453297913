import Vue from 'vue';
import axios from 'axios';
import AUTH from '@/constants/auth.constant';
import ROUTES from '@/constants/routes.constant';
import router from '@/router';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_SERVER_ADDRESS,
  withCredentials: true,
});

/**
 * @todo implement system-level error alerts
 */
axiosInstance.interceptors.response.use(
  (response) => {
    const { config, data } = response;
    if (data.errors) {
      data.errors.forEach((error) => {
        Vue.notify({
          group: 'api-level',
          title: `${error.status} ${error.title}`,
          text: error.detail,
          type: 'error',
        });
      });
    }
    if (config.url === '/em/job_schedulers/on-demand') {
      Vue.notify({
        group: 'api-level',
        title: 'Success',
        text: 'The task has been scheduled successfully, to see the status of the last execution, please refresh the page.',
        type: 'success',
      });
    } else if (config.method !== 'get') {
      Vue.notify({
        group: 'api-level',
        title: 'Success',
        text: 'Operation accomplished successfully',
        type: 'success',
      });
    };

    return response;
  },
  ({ response }) => {
    const { data, status, headers } = response;

    if (data.errors) {
      data.errors.forEach((error) => {
        Vue.notify({
          group: 'api-level',
          title: `${error.status} ${error.title}`,
          text: error.detail,
          type: 'error',
        });
      });
    }

    if (status === 403 && headers.logout === 'true') {
      localStorage.removeItem(AUTH.USER_KEY);
      router.push({
        name: ROUTES.SIGN_IN,
      });
    }

    return Promise.reject(response);
  }
);

export default axiosInstance;
