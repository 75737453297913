import { COMMON } from '@/constants/store.constant';

const initialState = () => ({
  parsersjobSchedulers: {},
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    [COMMON.UPDATE_STATE] (state, { jobs, parserId }) {
      state.parsersjobSchedulers[parserId] = jobs;
    },
    [COMMON.RESET_STATE] (state) {
      Object.assign(state, initialState());
    },
  },
  getters: {
    [COMMON.GET_STATE] (state) {
      return (id) => state.parsersjobSchedulers[id];
    },
  },
};
