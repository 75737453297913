/**
 * @returns {number} Returns a random number
 */
export const randomId = () => Math.floor(Date.now() * Math.random());

/**
 * @returns {number} Returns a formatted date
 */
export const formatDate = (date) => date && new Date(date).toLocaleString();

/**
 * @returns {string} Returns a formatted word
 */
export const textFormatter = (string) => {
  const words = string.split('_');
  return words.map((word) => {
    return word[0].toUpperCase() + word.substring(1).toLowerCase();
  }).join(' ');
};

/**
 * @returns {string} Returns a word with the first lettet on uppercase
 */
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

/**
 * @returns {string} Returns the environment of the site
 */
export const getEnvironmentShortName = (env) => {
  switch (env) {
    case 'development':
      return 'DEV';
    case 'staging':
      return 'STG';
    case 'production':
      return 'PROD';
    default:
      return 'DEV';
  }
};

/**
 * @returns {string} Returns a Date JS object in format Y-m-d
 */
export const formatDateToYMD = (date) => {
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};

/**
 * Calculates the difference in minutes between two dates.
 *
 * @param {Date|string} date1 - The first date. Can be a Date object or a string that can be converted into a date.
 * @param {Date|string} date2 - The second date. Can be a Date object or a string that can be converted into a date.
 * @returns {number|null} The time difference between the two dates in minutes. Returns null if either of the dates is invalid.
 *
 */
export const dateDiff = (date1, date2) => {
  if (!date1 || !date2) {
    return null;
  }
  const diff = Math.abs(new Date(date1) - new Date(date2));
  return Math.floor((diff / 1000) / 60);
};
