export const COMMON = {
  FETCH_DATA: 'FETCH_DATA',
  GET_STATE: 'GET_STATE',
  SET_STATE: 'SET_STATE',
  RESET_STATE: 'RESET_STATE',
  RESET_PARAMS: 'RESET_PARAMS',
  IS_LAST_PAGE: 'IS_LAST_PAGE',
  IS_LOADING: 'IS_LOADING',
  UPDATE_STATE: 'UPDATE_STATE',
  UPDATE_STATE_PAYLOAD: 'UPDATE_STATE_PAYLOAD',
  UPDATE_PARAM: 'UPDATE_PARAM',
  UPDATE_PARAMS: 'UPDATE_PARAMS',
  FILTER_DATA: 'FILTER_DATA',
  SHOULD_REFRESH: 'SHOULD_REFRESH',
  GET_STATE_BY_NAME: 'GET_STATE_BY_NAME',
  INITIAL_FETCH: 'INITIAL_FETCH',
};

export const MODULE = {
  PROVIDERS: 'PROVIDERS',
  PROVIDER: 'PROVIDER',
  PROVIDER_LINKS: 'PROVIDER_LINKS',
  COLLECTION: 'COLLECTION',
  JOB: 'JOB',
  AUTH: 'AUTH',
  EXCHANGE_MONITORS: 'EXCHANGE_MONITORS',
  EXCHANGE_MONITOR: 'EXCHANGE_MONITOR',
  PARSERS: 'PARSERS',
  FEATURE_FLAGS: 'FEATURE_FLAGS',
  PARSERS_JOB_SCHEDULERS: 'PARSERS_JOB_SCHEDULERS',
  COLLECTION_EXCHANGE_MONITOR_MODULE: 'COLLECTION_EXCHANGE_MONITOR_MODULE',
  HISTORY_EXCHANGE_MONITOR: 'HISTORY_EXCHANGE_MONITOR',
  CRAWLED_DOCUMENTS_EM: 'CRAWLED_DOCUMENTS_EM',
  COLLECTION_PR_EC_MODULE: 'COLLECTION_PR_EC_MODULE',
};
