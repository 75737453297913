import { COMMON } from '@/constants/store.constant';
import { getJobExecutions } from '@/services/api/exchange-monitors.service';
import { dateDiff } from '@/utils/global.util';

const parseInformation = (data) => {
  return data.map((item) => {
    const lastExecutedDate = item.jobExecutionStatsDTO?.lastExecution;
    const createdAt = item.createdAt;
    return {
      name: item.parserDTO?.displayName,
      url: item.parserDTO?.url,
      id: item.profileId,
      parserId: item.parserDTO.id,
      jobId: item.id,
      isActive: item.parserDTO?.active,
      type: item.parserDTO?.type,
      createdAt,
      crawlingTimeframeStart: item.crawlingTimeframeStart,
      lastExecutedDate,
      executions: item.jobExecutionStatsDTO?.totalExecutions,
      duration: dateDiff(lastExecutedDate, createdAt),
      totalFiles: item.jobExecutionStatsDTO?.totalFoundFiles,
      status: item.status,
      lastExecutions: [
        {
          batchId: item.awsBatchUuid,
          status: item.status,
          stage: item.stage,
          newFiles: item.newFoundFiles,
          foundFiles: item.foundFiles,
          foundLinks: item.foundLinks,
          downloadingErrors: item.downloadingErrors,
          numBrokenLinks: item.numBrokenLinks,
          s3ReportUrl: item.s3ReportUrl,
          error: item.error,
        }
      ],
    };
  });
};

const filterParsersByName = (parsers, filterText) => {
  return filterText === null ? parsers : parsers.filter((parser) => parser.name.toLowerCase().includes(filterText.toLowerCase()) ||
  parser.url.toLowerCase().includes(filterText.toLowerCase()) || parser.id.toLowerCase().includes(filterText.toLowerCase()));
};

const filterParsersByDateRange = (parsers, startDate, endDate) => {
  endDate && endDate.setDate(endDate.getDate() + 1);
  return startDate === null || endDate == null ? parsers : parsers.filter((parser) => {
    const lastExecutedDate = new Date(parser.lastExecutedDate);
    return lastExecutedDate > startDate && lastExecutedDate < endDate;
  });
};

const initialState = () => ({
  parsers: [],
  filterParsers: [],
  params: {
    page: 0,
    size: 40,
  },
  isLastPage: true,
  filterText: '',
  startDate: null,
  endDate: null,
  totalElements: 0,
  shouldRefresh: false,
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    [COMMON.UPDATE_STATE] (state, { name, value }) {
      state[name] = value;
    },
    [COMMON.UPDATE_PARAM] (state, { name, value }) {
      state.params[name] = value;
    },
    [COMMON.RESET_STATE] (state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    async [COMMON.FETCH_DATA] ({ state, commit }, { shouldReset }) {
      commit(COMMON.UPDATE_PARAM, { name: 'page', value: shouldReset ? 0 : state.params.page + 1 });
      let data;
      if (state.parsers.length > 0 && !state.shouldRefresh) {
        data = state.parsers;
      } else {
        const result = await getJobExecutions(state.params);
        data = parseInformation(result.data);
      }
      commit(COMMON.UPDATE_STATE, { name: 'parsers', value: data });
      const filteredData = filterParsersByName(filterParsersByDateRange(data, state.startDate, state.endDate), state.filterText);
      commit(COMMON.UPDATE_STATE, { name: 'filterParsers', value: filteredData });
    },
    [COMMON.FILTER_DATA] ({ state, commit }) {
      const data = filterParsersByName(filterParsersByDateRange(state.parsers, state.startDate, state.endDate), state.filterText);
      commit(COMMON.UPDATE_STATE, { name: 'filterParsers', value: data });
    },
  },
  getters: {
    [COMMON.GET_STATE] (state) {
      return state.filterParsers;
    },
    [COMMON.IS_LAST_PAGE] (state) {
      return state.isLastPage;
    },
    [COMMON.SHOULD_REFRESH] (state) {
      return state.shouldRefresh;
    },
    [COMMON.GET_STATE_BY_NAME] (state) {
      return (name) => state[name];
    },
  },
};
