export default {
  message: {
    hello: 'hello word',
  },
  exchangeMonitors: {
    id: 'Id',
    name: 'Name',
    priority: 'Priority',
    active: 'Status',
    approved: 'Approved',
    documentGroup: 'Document Group',
    comment: 'Comment',
    checkDuplicates: 'Check Duplicates',
    dataSource: 'Data Source',
    expDatePreDuplication: 'Expiration Date Pre Duplication',
    emUrl: 'Exchange Monitor Url',
    creatorUser: 'Creator User',
    lastModifierUser: 'Last Modifier User',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    externalProfileId: 'Profile Uuid',
    sheduleJobNowTitle: 'Execution Confirmation',
    sheduleJobNow: 'Please select the date that you want to run:',
  },

  collectionPageEM: {
    title: 'Last Job Executions',
    lastExecution: 'Last Execution:',
    filterLabel: 'Search by Name, Profile Uuid or URL',
    lastExecutionDate: 'Last Execution Date Range',
    startDateLabel: 'Start Date',
    endDateLabel: 'End Date',
    searchButton: 'Apply',
  },

  cronExpressions: {
    title: 'Scheduler Expression (UTC)',
    name: 'Name of Scheduler Expression',
    displayName: 'Display Name: ',
    awsMsg: 'These Scheduler Expressions are Based on UTC AWS Schedule Expressions,',
    awsMsgSeeMore: '(See more)',
    minutesLabel: 'On (x) Minutes',
    hoursLabel: 'On (x) Hour(s)',
    dailyLabel: 'Day (x) Of Month',
    monthlyLabel: 'Every (x) Month(s)',
    advancedLabel: 'Scheduler expression',
    finalExpression: 'Final Expression',
    executableTime: 'Executable time',
    addExpression: 'Add Expression',
    editExpression: 'Edit Expression',
    deleteCronExpression: 'Are you sure you want to delete this scheduler expression?',
    maxLength: 'You are able to add just 10 scheduler expression per parser.',
    retroactivityDays: 'Days',
    retroactivityDaysMsg: 'Please provide days between 1-30 only',
    retroactivityDaysErrorTitle: 'Retroactivity Days',
    retroactivityDaysErrorMsg: 'The number of days must be between 1 and 30.',
    retroactivityInvalidNumberTitle: 'Retroactivity Days.',
    retroactivityInvalidNumberMsg: 'Retroactivity days must be a number between 1 and 30.',
    isCronExpressionUnique: 'This cron expression already exists for this Parser.',
  },

  crawledDocuments: {
    title: 'Crawled Documents',
    copied: 'Attributes Copied!',
    filter: 'Search by Downloaded Link, S3 Path or Attributes data',
    searchLabel: 'Search by Downloaded Link, S3 Path or Attributes data',
  },

  colletionPageCP: {
    sheduleJobNowTitle: 'Execution Confirmation',
    sheduleJobNow: 'Are you sure you want to execute this job?',
  },

  history: {
    title: 'Job Executions',
    lastExecutionDate: 'Filters',
    startDateLabel: 'Start Date (UTC 00:00)',
    endDateLabel: 'End Date (UTC 00:00)',
    startDateError: 'Select Start Date',
    endDateError: 'Select End Date',
    searchButton: 'Apply',
    parserLabel: 'Parser Name',
  },

  providerLinks: {
    link: 'Link',
    indexType: 'Index Type',
    containerXPath: 'Container XPath',
    frequency: 'Frequency',
    documentType: 'Document Type',
    s3Buckets: 'S3 Bucket',
    status: 'Status',
    active: 'Active',
    actions: 'Actions',
    remove: 'Remove',
    addLink: 'Add Link',
  },

  collectionPrEcPage: {
    title: 'Last Job Executions',
    filterLabel: 'Search by Company (ID, Name) or Link URL',
    copied: 'Error Msg Copied!',
  },
};
