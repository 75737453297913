import AUTH from '@/constants/auth.constant';
import { COMMON } from '@/constants/store.constant';
import { logIn, logOut } from '@/services/api/auth.service';

const initialState = () => ({
  isLoggedIn: false,
  username: '',
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    [COMMON.UPDATE_STATE] (state, { name, value }) {
      state[name] = value;
    },
    [COMMON.RESET_STATE] (state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    async [AUTH.LOG_IN] ({ dispatch }, auth) {
      await logIn(auth);
      localStorage.setItem(AUTH.USER_KEY, auth.username);
      dispatch(AUTH.INIT_SESSION);
    },
    [AUTH.LOG_OUT] ({ dispatch }) {
      logOut();
      localStorage.removeItem(AUTH.USER_KEY);
      dispatch(AUTH.UPDATE_SESSION, { username: '', loginState: false });
      location.reload();
    },
    [AUTH.INIT_SESSION] ({ dispatch }) {
      dispatch(AUTH.UPDATE_SESSION, { username: localStorage.getItem(AUTH.USER_KEY), loginState: true });
    },
    [AUTH.UPDATE_SESSION] ({ commit }, { username, loginState }) {
      commit(COMMON.UPDATE_STATE, { name: 'username', value: username });
      commit(COMMON.UPDATE_STATE, { name: 'isLoggedIn', value: loginState });
    },
  },
  getters: {
    [COMMON.GET_STATE] (state) {
      return (name) => state[name];
    },
  },
};
